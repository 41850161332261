import React, { useContext } from 'react';
import find from 'lodash/fp/find';
import toLower from 'lodash/fp/toLower';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { SET_COUNTRY_CODE } from '../../constants';
import HiddenField from '../../../HiddenField';

import countryCodes from './country-codes';
import * as CountryCodeDropdownService from './CountryCodeDropdownService';

import SiteContext from '~/shared/providers/SiteContext';
import Dropdown from '~/shared/components/Dropdown';
import FlagIcon from '~/shared/components/icons/FlagIcon/FlagIcon';
import dataSelector from '~/shared/util/data-selector';

const dropdownStyles = ({ invalid, focused, theme }) => css`
  position: absolute;
  width: 124px;
  height: 100%;

  background-color: var(--cui-bg-normal);
  color: var(--cui-fg-normal);
  font-size: 16px;
  padding: 0;
  z-index: 21;
  top: 0;

  border-radius: ${theme.borderRadius.tera};
  border: none;

  div {
    height: 100%;
    width: 100%;

    border-color: ${CountryCodeDropdownService.getBorderColor(
      invalid,
      focused,
    )};
    border-radius: ${theme.borderRadius.tera};
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top: none;
    border-bottom: none;
    border-left: none;

    div {
      padding: ${theme.spacings.kilo} ${theme.spacings.mega};
      padding-left: ${theme.spacings.exa};
      margin-right: ${theme.spacings.mega};
    }
  }
`;

const StyledDropdown = styled(Dropdown)(dropdownStyles);

const flagIconStyles = ({ theme }) => css`
  display: block;
  position: absolute;
  top: 44%;
  left: ${theme.spacings.mega};
  width: 24px;
  height: 24px;
  pointer-events: none;
  z-index: 40;
  margin-top: -${theme.spacings.byte};
`;

const StyledFlagIcon = styled(FlagIcon)(flagIconStyles);

function CountryCodeDropdown({
  invalid,
  focused,
  dispatch = () => {},
  label,
  value = '',
}) {
  const site = useContext(SiteContext);
  const { websites = [], country: currentCountry } = site;

  const supportedCountryCodes = React.useMemo(
    () => CountryCodeDropdownService.getActiveWebsites(countryCodes, websites),
    [websites],
  );

  const currentItem = React.useMemo(
    () =>
      value
        ? find((country) => country.label === value, supportedCountryCodes)
        : find(
            (country) => country.countryCode === currentCountry.code,
            supportedCountryCodes,
          ),
    [currentCountry.code, supportedCountryCodes, value],
  );

  const direction = CountryCodeDropdownService.calculateDirection(
    currentItem,
    supportedCountryCodes,
  );

  React.useEffect(() => {
    if (currentItem.label !== value) {
      dispatch({ value: currentItem.label, type: SET_COUNTRY_CODE });
    }
  }, [currentItem, dispatch, value]);

  function handleChange(item) {
    dispatch({ value: item.label, type: SET_COUNTRY_CODE });
  }

  return (
    <>
      <StyledDropdown
        id="dropdown@form"
        data-selector={dataSelector('countryCodeDropdown', 'form')}
        items={supportedCountryCodes}
        initialItem={currentItem}
        direction={direction}
        renderLabelPrefix={({ item }) => (
          <StyledFlagIcon countryCode={toLower(item.countryCode)} />
        )}
        onChange={handleChange}
        hasPrefix
        invalid={invalid}
        focused={focused}
        aria-label={label}
        toggleTabIndex="-1"
        disableToggleHoverStyles
        disableToggleFocusStyles
      />
      <HiddenField name="Country_phone" value={currentItem.countryCode} />
    </>
  );
}

CountryCodeDropdown.propTypes = {
  invalid: PropTypes.bool,
  focused: PropTypes.bool,
  id: PropTypes.string,
  dispatch: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.string,
};

export default React.memo(CountryCodeDropdown);
