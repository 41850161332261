import React from 'react';

import dataSelector from '~/shared/util/data-selector';

/**
 * Hidden Form Field
 */
const HiddenField = ({
  name = '',
  value = '',
}: {
  name?: string;
  value?: string;
}): JSX.Element => (
  <input
    data-selector={dataSelector(name, 'hidden_form')}
    type="hidden"
    name={name}
    value={value}
  />
);

/**
 * @component
 */
export default HiddenField;
