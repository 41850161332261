import get from 'lodash/fp/get';
import findIndex from 'lodash/fp/findIndex';

import Dropdown from '~/shared/components/Dropdown';

export function getActiveWebsites(countryCodes, websites = []) {
  return websites.reduce((activeWebsites, website = {}) => {
    if (!website.isVisible) {
      return activeWebsites;
    }

    const websiteCountryCode = get('country.code', website);
    const countryCodeItem = countryCodes[websiteCountryCode];

    if (countryCodeItem) {
      activeWebsites.push(countryCodeItem);
    }

    return activeWebsites;
  }, []);
}

export function getBorderColor(invalid, focused) {
  if (invalid) {
    return 'var(--cui-border-danger)';
  }

  return focused
    ? 'var(--cui-border-accent-hovered)'
    : 'var(--cui-border-normal-hovered)';
}

export function calculateDirection(item = {}, allItems = []) {
  const foundIndex = findIndex((_item) => _item.name === item.name, allItems);

  return foundIndex > allItems.length / 2
    ? Dropdown.DIRECTIONS.UP
    : Dropdown.DIRECTIONS.DOWN;
}
