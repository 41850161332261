export default {
  AF: {
    name: 'Afghanistan',
    label: '+93',
    countryCode: 'AF',
  },
  AX: {
    name: 'Aland Islands',
    label: '+358',
    countryCode: 'AX',
  },
  AL: {
    name: 'Albania',
    label: '+355',
    countryCode: 'AL',
  },
  DZ: {
    name: 'Algeria',
    label: '+213',
    countryCode: 'DZ',
  },
  AS: {
    name: 'AmericanSamoa',
    label: '+1 684',
    countryCode: 'AS',
  },
  AD: {
    name: 'Andorra',
    label: '+376',
    countryCode: 'AD',
  },
  AO: {
    name: 'Angola',
    label: '+244',
    countryCode: 'AO',
  },
  AI: {
    name: 'Anguilla',
    label: '+1 264',
    countryCode: 'AI',
  },
  AQ: {
    name: 'Antarctica',
    label: '+672',
    countryCode: 'AQ',
  },
  AG: {
    name: 'Antigua and Barbuda',
    label: '+1268',
    countryCode: 'AG',
  },
  AR: {
    name: 'Argentina',
    label: '+54',
    countryCode: 'AR',
  },
  AM: {
    name: 'Armenia',
    label: '+374',
    countryCode: 'AM',
  },
  AW: {
    name: 'Aruba',
    label: '+297',
    countryCode: 'AW',
  },
  AU: {
    name: 'Australia',
    label: '+61',
    countryCode: 'AU',
  },
  AT: {
    name: 'Austria',
    label: '+43',
    countryCode: 'AT',
  },
  AZ: {
    name: 'Azerbaijan',
    label: '+994',
    countryCode: 'AZ',
  },
  BS: {
    name: 'Bahamas',
    label: '+1 242',
    countryCode: 'BS',
  },
  BH: {
    name: 'Bahrain',
    label: '+973',
    countryCode: 'BH',
  },
  BD: {
    name: 'Bangladesh',
    label: '+880',
    countryCode: 'BD',
  },
  BB: {
    name: 'Barbados',
    label: '+1 246',
    countryCode: 'BB',
  },
  BY: {
    name: 'Belarus',
    label: '+375',
    countryCode: 'BY',
  },
  BE: {
    name: 'Belgium',
    label: '+32',
    countryCode: 'BE',
  },
  BZ: {
    name: 'Belize',
    label: '+501',
    countryCode: 'BZ',
  },
  BJ: {
    name: 'Benin',
    label: '+229',
    countryCode: 'BJ',
  },
  BM: {
    name: 'Bermuda',
    label: '+1 441',
    countryCode: 'BM',
  },
  BT: {
    name: 'Bhutan',
    label: '+975',
    countryCode: 'BT',
  },
  BO: {
    name: 'Bolivia, Plurinational State of',
    label: '+591',
    countryCode: 'BO',
  },
  BA: {
    name: 'Bosnia and Herzegovina',
    label: '+387',
    countryCode: 'BA',
  },
  BW: {
    name: 'Botswana',
    label: '+267',
    countryCode: 'BW',
  },
  BR: {
    name: 'Brazil',
    label: '+55',
    countryCode: 'BR',
  },
  IO: {
    name: 'British Indian Ocean Territory',
    label: '+246',
    countryCode: 'IO',
  },
  BN: {
    name: 'Brunei Darussalam',
    label: '+673',
    countryCode: 'BN',
  },
  BG: {
    name: 'Bulgaria',
    label: '+359',
    countryCode: 'BG',
  },
  BF: {
    name: 'Burkina Faso',
    label: '+226',
    countryCode: 'BF',
  },
  BI: {
    name: 'Burundi',
    label: '+257',
    countryCode: 'BI',
  },
  KH: {
    name: 'Cambodia',
    label: '+855',
    countryCode: 'KH',
  },
  CM: {
    name: 'Cameroon',
    label: '+237',
    countryCode: 'CM',
  },
  CA: {
    name: 'Canada',
    label: '+1',
    countryCode: 'CA',
  },
  CV: {
    name: 'Cape Verde',
    label: '+238',
    countryCode: 'CV',
  },
  KY: {
    name: 'Cayman Islands',
    label: '+ 345',
    countryCode: 'KY',
  },
  CF: {
    name: 'Central African Republic',
    label: '+236',
    countryCode: 'CF',
  },
  TD: {
    name: 'Chad',
    label: '+235',
    countryCode: 'TD',
  },
  CL: {
    name: 'Chile',
    label: '+56',
    countryCode: 'CL',
  },
  CN: {
    name: 'China',
    label: '+86',
    countryCode: 'CN',
  },
  CX: {
    name: 'Christmas Island',
    label: '+61',
    countryCode: 'CX',
  },
  CC: {
    name: 'Cocos (Keeling) Islands',
    label: '+61',
    countryCode: 'CC',
  },
  CO: {
    name: 'Colombia',
    label: '+57',
    countryCode: 'CO',
  },
  KM: {
    name: 'Comoros',
    label: '+269',
    countryCode: 'KM',
  },
  CG: {
    name: 'Congo',
    label: '+242',
    countryCode: 'CG',
  },
  CD: {
    name: 'Congo, The Democratic Republic of the Congo',
    label: '+243',
    countryCode: 'CD',
  },
  CK: {
    name: 'Cook Islands',
    label: '+682',
    countryCode: 'CK',
  },
  CR: {
    name: 'Costa Rica',
    label: '+506',
    countryCode: 'CR',
  },
  CI: {
    name: 'Cote d’Ivoire',
    label: '+225',
    countryCode: 'CI',
  },
  HR: {
    name: 'Croatia',
    label: '+385',
    countryCode: 'HR',
  },
  CU: {
    name: 'Cuba',
    label: '+53',
    countryCode: 'CU',
  },
  CY: {
    name: 'Cyprus',
    label: '+357',
    countryCode: 'CY',
  },
  CZ: {
    name: 'Czech Republic',
    label: '+420',
    countryCode: 'CZ',
  },
  DK: {
    name: 'Denmark',
    label: '+45',
    countryCode: 'DK',
  },
  DJ: {
    name: 'Djibouti',
    label: '+253',
    countryCode: 'DJ',
  },
  DM: {
    name: 'Dominica',
    label: '+1 767',
    countryCode: 'DM',
  },
  DO: {
    name: 'Dominican Republic',
    label: '+1 849',
    countryCode: 'DO',
  },
  EC: {
    name: 'Ecuador',
    label: '+593',
    countryCode: 'EC',
  },
  EG: {
    name: 'Egypt',
    label: '+20',
    countryCode: 'EG',
  },
  SV: {
    name: 'El Salvador',
    label: '+503',
    countryCode: 'SV',
  },
  GQ: {
    name: 'Equatorial Guinea',
    label: '+240',
    countryCode: 'GQ',
  },
  ER: {
    name: 'Eritrea',
    label: '+291',
    countryCode: 'ER',
  },
  EE: {
    name: 'Estonia',
    label: '+372',
    countryCode: 'EE',
  },
  ET: {
    name: 'Ethiopia',
    label: '+251',
    countryCode: 'ET',
  },
  FK: {
    name: 'Falkland Islands (Malvinas)',
    label: '+500',
    countryCode: 'FK',
  },
  FO: {
    name: 'Faroe Islands',
    label: '+298',
    countryCode: 'FO',
  },
  FJ: {
    name: 'Fiji',
    label: '+679',
    countryCode: 'FJ',
  },
  FI: {
    name: 'Finland',
    label: '+358',
    countryCode: 'FI',
  },
  FR: {
    name: 'France',
    label: '+33',
    countryCode: 'FR',
  },
  GF: {
    name: 'French Guiana',
    label: '+594',
    countryCode: 'GF',
  },
  PF: {
    name: 'French Polynesia',
    label: '+689',
    countryCode: 'PF',
  },
  GA: {
    name: 'Gabon',
    label: '+241',
    countryCode: 'GA',
  },
  GM: {
    name: 'Gambia',
    label: '+220',
    countryCode: 'GM',
  },
  GE: {
    name: 'Georgia',
    label: '+995',
    countryCode: 'GE',
  },
  DE: {
    name: 'Germany',
    label: '+49',
    countryCode: 'DE',
  },
  GH: {
    name: 'Ghana',
    label: '+233',
    countryCode: 'GH',
  },
  GI: {
    name: 'Gibraltar',
    label: '+350',
    countryCode: 'GI',
  },
  GR: {
    name: 'Greece',
    label: '+30',
    countryCode: 'GR',
  },
  GL: {
    name: 'Greenland',
    label: '+299',
    countryCode: 'GL',
  },
  GD: {
    name: 'Grenada',
    label: '+1 473',
    countryCode: 'GD',
  },
  GP: {
    name: 'Guadeloupe',
    label: '+590',
    countryCode: 'GP',
  },
  GU: {
    name: 'Guam',
    label: '+1 671',
    countryCode: 'GU',
  },
  GT: {
    name: 'Guatemala',
    label: '+502',
    countryCode: 'GT',
  },
  GG: {
    name: 'Guernsey',
    label: '+44',
    countryCode: 'GG',
  },
  GN: {
    name: 'Guinea',
    label: '+224',
    countryCode: 'GN',
  },
  GW: {
    name: 'Guinea-Bissau',
    label: '+245',
    countryCode: 'GW',
  },
  GY: {
    name: 'Guyana',
    label: '+595',
    countryCode: 'GY',
  },
  HT: {
    name: 'Haiti',
    label: '+509',
    countryCode: 'HT',
  },
  VA: {
    name: 'Holy See (Vatican City State)',
    label: '+379',
    countryCode: 'VA',
  },
  HN: {
    name: 'Honduras',
    label: '+504',
    countryCode: 'HN',
  },
  HK: {
    name: 'Hong Kong',
    label: '+852',
    countryCode: 'HK',
  },
  HU: {
    name: 'Hungary',
    label: '+36',
    countryCode: 'HU',
  },
  IS: {
    name: 'Iceland',
    label: '+354',
    countryCode: 'IS',
  },
  IN: {
    name: 'India',
    label: '+91',
    countryCode: 'IN',
  },
  ID: {
    name: 'Indonesia',
    label: '+62',
    countryCode: 'ID',
  },
  IR: {
    name: 'Iran, Islamic Republic of Persian Gulf',
    label: '+98',
    countryCode: 'IR',
  },
  IQ: {
    name: 'Iraq',
    label: '+964',
    countryCode: 'IQ',
  },
  IE: {
    name: 'Ireland',
    label: '+353',
    countryCode: 'IE',
  },
  IM: {
    name: 'Isle of Man',
    label: '+44',
    countryCode: 'IM',
  },
  IL: {
    name: 'Israel',
    label: '+972',
    countryCode: 'IL',
  },
  IT: {
    name: 'Italy',
    label: '+39',
    countryCode: 'IT',
  },
  JM: {
    name: 'Jamaica',
    label: '+1 876',
    countryCode: 'JM',
  },
  JP: {
    name: 'Japan',
    label: '+81',
    countryCode: 'JP',
  },
  JE: {
    name: 'Jersey',
    label: '+44',
    countryCode: 'JE',
  },
  JO: {
    name: 'Jordan',
    label: '+962',
    countryCode: 'JO',
  },
  KZ: {
    name: 'Kazakhstan',
    label: '+7 7',
    countryCode: 'KZ',
  },
  KE: {
    name: 'Kenya',
    label: '+254',
    countryCode: 'KE',
  },
  KI: {
    name: 'Kiribati',
    label: '+686',
    countryCode: 'KI',
  },
  KP: {
    name: 'Korea, Democratic People’s Republic of Korea',
    label: '+850',
    countryCode: 'KP',
  },
  KR: {
    name: 'Korea, Republic of South Korea',
    label: '+82',
    countryCode: 'KR',
  },
  KW: {
    name: 'Kuwait',
    label: '+965',
    countryCode: 'KW',
  },
  KG: {
    name: 'Kyrgyzstan',
    label: '+996',
    countryCode: 'KG',
  },
  LA: {
    name: 'Laos',
    label: '+856',
    countryCode: 'LA',
  },
  LV: {
    name: 'Latvia',
    label: '+371',
    countryCode: 'LV',
  },
  LB: {
    name: 'Lebanon',
    label: '+961',
    countryCode: 'LB',
  },
  LS: {
    name: 'Lesotho',
    label: '+266',
    countryCode: 'LS',
  },
  LR: {
    name: 'Liberia',
    label: '+231',
    countryCode: 'LR',
  },
  LY: {
    name: 'Libyan Arab Jamahiriya',
    label: '+218',
    countryCode: 'LY',
  },
  LI: {
    name: 'Liechtenstein',
    label: '+423',
    countryCode: 'LI',
  },
  LT: {
    name: 'Lithuania',
    label: '+370',
    countryCode: 'LT',
  },
  LU: {
    name: 'Luxembourg',
    label: '+352',
    countryCode: 'LU',
  },
  MO: {
    name: 'Macao',
    label: '+853',
    countryCode: 'MO',
  },
  MK: {
    name: 'Macedonia',
    label: '+389',
    countryCode: 'MK',
  },
  MG: {
    name: 'Madagascar',
    label: '+261',
    countryCode: 'MG',
  },
  MW: {
    name: 'Malawi',
    label: '+265',
    countryCode: 'MW',
  },
  MY: {
    name: 'Malaysia',
    label: '+60',
    countryCode: 'MY',
  },
  MV: {
    name: 'Maldives',
    label: '+960',
    countryCode: 'MV',
  },
  ML: {
    name: 'Mali',
    label: '+223',
    countryCode: 'ML',
  },
  MT: {
    name: 'Malta',
    label: '+356',
    countryCode: 'MT',
  },
  MH: {
    name: 'Marshall Islands',
    label: '+692',
    countryCode: 'MH',
  },
  MQ: {
    name: 'Martinique',
    label: '+596',
    countryCode: 'MQ',
  },
  MR: {
    name: 'Mauritania',
    label: '+222',
    countryCode: 'MR',
  },
  MU: {
    name: 'Mauritius',
    label: '+230',
    countryCode: 'MU',
  },
  YT: {
    name: 'Mayotte',
    label: '+262',
    countryCode: 'YT',
  },
  MX: {
    name: 'Mexico',
    label: '+52',
    countryCode: 'MX',
  },
  FM: {
    name: 'Micronesia, Federated States of Micronesia',
    label: '+691',
    countryCode: 'FM',
  },
  MD: {
    name: 'Moldova',
    label: '+373',
    countryCode: 'MD',
  },
  MC: {
    name: 'Monaco',
    label: '+377',
    countryCode: 'MC',
  },
  MN: {
    name: 'Mongolia',
    label: '+976',
    countryCode: 'MN',
  },
  ME: {
    name: 'Montenegro',
    label: '+382',
    countryCode: 'ME',
  },
  MS: {
    name: 'Montserrat',
    label: '+1664',
    countryCode: 'MS',
  },
  MA: {
    name: 'Morocco',
    label: '+212',
    countryCode: 'MA',
  },
  MZ: {
    name: 'Mozambique',
    label: '+258',
    countryCode: 'MZ',
  },
  MM: {
    name: 'Myanmar',
    label: '+95',
    countryCode: 'MM',
  },
  NA: {
    name: 'Namibia',
    label: '+264',
    countryCode: 'NA',
  },
  NR: {
    name: 'Nauru',
    label: '+674',
    countryCode: 'NR',
  },
  NP: {
    name: 'Nepal',
    label: '+977',
    countryCode: 'NP',
  },
  NL: {
    name: 'Netherlands',
    label: '+31',
    countryCode: 'NL',
  },
  AN: {
    name: 'Netherlands Antilles',
    label: '+599',
    countryCode: 'AN',
  },
  NC: {
    name: 'New Caledonia',
    label: '+687',
    countryCode: 'NC',
  },
  NZ: {
    name: 'New Zealand',
    label: '+64',
    countryCode: 'NZ',
  },
  NI: {
    name: 'Nicaragua',
    label: '+505',
    countryCode: 'NI',
  },
  NE: {
    name: 'Niger',
    label: '+227',
    countryCode: 'NE',
  },
  NG: {
    name: 'Nigeria',
    label: '+234',
    countryCode: 'NG',
  },
  NU: {
    name: 'Niue',
    label: '+683',
    countryCode: 'NU',
  },
  NF: {
    name: 'Norfolk Island',
    label: '+672',
    countryCode: 'NF',
  },
  MP: {
    name: 'Northern Mariana Islands',
    label: '+1 670',
    countryCode: 'MP',
  },
  NO: {
    name: 'Norway',
    label: '+47',
    countryCode: 'NO',
  },
  OM: {
    name: 'Oman',
    label: '+968',
    countryCode: 'OM',
  },
  PK: {
    name: 'Pakistan',
    label: '+92',
    countryCode: 'PK',
  },
  PW: {
    name: 'Palau',
    label: '+680',
    countryCode: 'PW',
  },
  PS: {
    name: 'Palestinian Territory, Occupied',
    label: '+970',
    countryCode: 'PS',
  },
  PA: {
    name: 'Panama',
    label: '+507',
    countryCode: 'PA',
  },
  PG: {
    name: 'Papua New Guinea',
    label: '+675',
    countryCode: 'PG',
  },
  PY: {
    name: 'Paraguay',
    label: '+595',
    countryCode: 'PY',
  },
  PE: {
    name: 'Peru',
    label: '+51',
    countryCode: 'PE',
  },
  PH: {
    name: 'Philippines',
    label: '+63',
    countryCode: 'PH',
  },
  PN: {
    name: 'Pitcairn',
    label: '+872',
    countryCode: 'PN',
  },
  PL: {
    name: 'Poland',
    label: '+48',
    countryCode: 'PL',
  },
  PT: {
    name: 'Portugal',
    label: '+351',
    countryCode: 'PT',
  },
  PR: {
    name: 'Puerto Rico',
    label: '+1 939',
    countryCode: 'PR',
  },
  QA: {
    name: 'Qatar',
    label: '+974',
    countryCode: 'QA',
  },
  RO: {
    name: 'Romania',
    label: '+40',
    countryCode: 'RO',
  },
  RU: {
    name: 'Russia',
    label: '+7',
    countryCode: 'RU',
  },
  RW: {
    name: 'Rwanda',
    label: '+250',
    countryCode: 'RW',
  },
  RE: {
    name: 'Reunion',
    label: '+262',
    countryCode: 'RE',
  },
  BL: {
    name: 'Saint Barthelemy',
    label: '+590',
    countryCode: 'BL',
  },
  SH: {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    label: '+290',
    countryCode: 'SH',
  },
  KN: {
    name: 'Saint Kitts and Nevis',
    label: '+1 869',
    countryCode: 'KN',
  },
  LC: {
    name: 'Saint Lucia',
    label: '+1 758',
    countryCode: 'LC',
  },
  MF: {
    name: 'Saint Martin',
    label: '+590',
    countryCode: 'MF',
  },
  PM: {
    name: 'Saint Pierre and Miquelon',
    label: '+508',
    countryCode: 'PM',
  },
  VC: {
    name: 'Saint Vincent and the Grenadines',
    label: '+1 784',
    countryCode: 'VC',
  },
  WS: {
    name: 'Samoa',
    label: '+685',
    countryCode: 'WS',
  },
  SM: {
    name: 'San Marino',
    label: '+378',
    countryCode: 'SM',
  },
  ST: {
    name: 'Sao Tome and Principe',
    label: '+239',
    countryCode: 'ST',
  },
  SA: {
    name: 'Saudi Arabia',
    label: '+966',
    countryCode: 'SA',
  },
  SN: {
    name: 'Senegal',
    label: '+221',
    countryCode: 'SN',
  },
  RS: {
    name: 'Serbia',
    label: '+381',
    countryCode: 'RS',
  },
  SC: {
    name: 'Seychelles',
    label: '+248',
    countryCode: 'SC',
  },
  SL: {
    name: 'Sierra Leone',
    label: '+232',
    countryCode: 'SL',
  },
  SG: {
    name: 'Singapore',
    label: '+65',
    countryCode: 'SG',
  },
  SK: {
    name: 'Slovakia',
    label: '+421',
    countryCode: 'SK',
  },
  SI: {
    name: 'Slovenia',
    label: '+386',
    countryCode: 'SI',
  },
  SB: {
    name: 'Solomon Islands',
    label: '+677',
    countryCode: 'SB',
  },
  SO: {
    name: 'Somalia',
    label: '+252',
    countryCode: 'SO',
  },
  ZA: {
    name: 'South Africa',
    label: '+27',
    countryCode: 'ZA',
  },
  GS: {
    name: 'South Georgia and the South Sandwich Islands',
    label: '+500',
    countryCode: 'GS',
  },
  ES: {
    name: 'Spain',
    label: '+34',
    countryCode: 'ES',
  },
  LK: {
    name: 'Sri Lanka',
    label: '+94',
    countryCode: 'LK',
  },
  SD: {
    name: 'Sudan',
    label: '+249',
    countryCode: 'SD',
  },
  SR: {
    name: 'Suriname',
    label: '+597',
    countryCode: 'SR',
  },
  SJ: {
    name: 'Svalbard and Jan Mayen',
    label: '+47',
    countryCode: 'SJ',
  },
  SZ: {
    name: 'Swaziland',
    label: '+268',
    countryCode: 'SZ',
  },
  SE: {
    name: 'Sweden',
    label: '+46',
    countryCode: 'SE',
  },
  CH: {
    name: 'Switzerland',
    label: '+41',
    countryCode: 'CH',
  },
  SY: {
    name: 'Syrian Arab Republic',
    label: '+963',
    countryCode: 'SY',
  },
  TW: {
    name: 'Taiwan',
    label: '+886',
    countryCode: 'TW',
  },
  TJ: {
    name: 'Tajikistan',
    label: '+992',
    countryCode: 'TJ',
  },
  TZ: {
    name: 'Tanzania, United Republic of Tanzania',
    label: '+255',
    countryCode: 'TZ',
  },
  TH: {
    name: 'Thailand',
    label: '+66',
    countryCode: 'TH',
  },
  TL: {
    name: 'Timor-Leste',
    label: '+670',
    countryCode: 'TL',
  },
  TG: {
    name: 'Togo',
    label: '+228',
    countryCode: 'TG',
  },
  TK: {
    name: 'Tokelau',
    label: '+690',
    countryCode: 'TK',
  },
  TO: {
    name: 'Tonga',
    label: '+676',
    countryCode: 'TO',
  },
  TT: {
    name: 'Trinidad and Tobago',
    label: '+1 868',
    countryCode: 'TT',
  },
  TN: {
    name: 'Tunisia',
    label: '+216',
    countryCode: 'TN',
  },
  TR: {
    name: 'Turkey',
    label: '+90',
    countryCode: 'TR',
  },
  TM: {
    name: 'Turkmenistan',
    label: '+993',
    countryCode: 'TM',
  },
  TC: {
    name: 'Turks and Caicos Islands',
    label: '+1 649',
    countryCode: 'TC',
  },
  TV: {
    name: 'Tuvalu',
    label: '+688',
    countryCode: 'TV',
  },
  UG: {
    name: 'Uganda',
    label: '+256',
    countryCode: 'UG',
  },
  UA: {
    name: 'Ukraine',
    label: '+380',
    countryCode: 'UA',
  },
  AE: {
    name: 'United Arab Emirates',
    label: '+971',
    countryCode: 'AE',
  },
  GB: {
    name: 'United Kingdom',
    label: '+44',
    countryCode: 'GB',
  },
  US: {
    name: 'United States',
    label: '+1',
    countryCode: 'US',
  },
  UY: {
    name: 'Uruguay',
    label: '+598',
    countryCode: 'UY',
  },
  UZ: {
    name: 'Uzbekistan',
    label: '+998',
    countryCode: 'UZ',
  },
  VU: {
    name: 'Vanuatu',
    label: '+678',
    countryCode: 'VU',
  },
  VE: {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    label: '+58',
    countryCode: 'VE',
  },
  VN: {
    name: 'Vietnam',
    label: '+84',
    countryCode: 'VN',
  },
  VG: {
    name: 'Virgin Islands, British',
    label: '+1 284',
    countryCode: 'VG',
  },
  VI: {
    name: 'Virgin Islands, U.S.',
    label: '+1 340',
    countryCode: 'VI',
  },
  WF: {
    name: 'Wallis and Futuna',
    label: '+681',
    countryCode: 'WF',
  },
  YE: {
    name: 'Yemen',
    label: '+967',
    countryCode: 'YE',
  },
  ZM: {
    name: 'Zambia',
    label: '+260',
    countryCode: 'ZM',
  },
  ZW: {
    name: 'Zimbabwe',
    label: '+263',
    countryCode: 'ZW',
  },
};
