import qs from 'querystring';

import React, {
  useState,
  useMemo,
  useReducer,
  useContext,
  useRef,
  useEffect,
} from 'react';
import { uniqueId } from '@sumup/circuit-ui/legacy';
import flow from 'lodash/fp/flow';
import isEmpty from 'lodash/fp/isEmpty';
import noop from 'lodash/fp/noop';
import { useRouter } from 'next/router';
import { elb } from '@elbwalker/walker.js';

import * as FormService from './FormService';
import * as configs from './field-configs';
import TextField from './components/TextField';
import SelectField from './components/SelectField';
import CheckboxField from './components/CheckboxField';
import HiddenField from './components/HiddenField';
import QuizField from './components/QuizField';
import {
  LEAD_RECEIVERS_CONTENTFUL_OPTIONS,
  LeadReceiversContentfulOptionsType,
} from './constants';

import * as OptimizelyFullStack from '~/shared/services/optimizely/optimizely-browser-client';
import * as Analytics from '~/shared/services/analytics';
import { createLead } from '~/domains/sales-leads/services/client';
import { LeadContext } from '~/domains/sales-leads/services/interfaces';
import SiteContext from '~/shared/providers/SiteContext';
import dataSelector from '~/shared/util/data-selector';
import isServer from '~/shared/util/is-server';
import { mapVariations } from '~/shared/services/optimizely/OptimizelyVariationsService';
import useOptimizelyData from '~/shared/services/optimizely/use-optimizely-data';
import { StoreContext } from '~/shared/providers/StoreContext';
import { sendNinetailedEvent } from '~/shared/services/ninetailed/events';
import { FormFieldType } from '~/shared/types/shared';

const DATA_SELECTOR = 'form';

const { hiddenField } = configs;

const components = {
  textField: TextField,
  selectField: SelectField,
  checkboxField: CheckboxField,
  hiddenField: HiddenField,
  quizField: QuizField,
};

export interface FormProps {
  fields: FormFieldType[];
  /**
   * An optional callback that is called on successful form submission.
   * It will NOT be called if the form is invalid.
   */
  onSubmit: typeof noop;
  /**
   * A three character identifier to route the form submission to the correct
   * queue in Salesforce.
   */
  departmentId: string;
  /**
   * The URL of the page where the user should be redirected after
   * a successful form submission.
   */
  redirectUrl?: string;
  /**
   * A short label that marks non-required fields as optional.
   */
  optionalLabel: string;
  /**
   * This message is shown to the user when they haven't filled out
   * a required field.
   */
  requiredMessage: string;
  /**
   * The form fields as a render function.
   * Receives an array of fields, where each field has the shape:
   * [Component, props]
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  children: (...args: any[]) => any;
  contentType?: string;
  name?: string;
  id?: string;
  submitButtonTrackingId: string;
  /*
   * Optimizely Full Stack click events for the submit button
   */
  submitButtonOptimizelyFullStackClickEvents: string[];
  /*
   * Optimizely Full Stack click events for the submit button
   */
  leadReceiver?: LeadReceiversContentfulOptionsType;
  /*
   * A list of query params to be set as hidden fields
   */
  queryParamsInHiddenFields: string[];
  /*
   * Light version that doesn't include extra fields. This is useful when including the form multiple times in the same page, for accesibility guidelines
   */
  lightVersion: boolean;
  setIsLoading?: typeof noop;
  initialValues: Record<string, unknown>;
}
/**
 * A Salesforce form with built-in validations.
 */

function Form({
  fields: originalFields = [],
  onSubmit,
  departmentId = '',
  redirectUrl,
  optionalLabel,
  requiredMessage,
  contentType,
  name,
  id,
  submitButtonOptimizelyFullStackClickEvents = [],
  children,
  leadReceiver = LEAD_RECEIVERS_CONTENTFUL_OPTIONS.MARKETING_CLOUD,
  queryParamsInHiddenFields = [],
  submitButtonTrackingId,
  lightVersion = false,
  setIsLoading = noop,
  initialValues,
}: FormProps) {
  const formRef = useRef();
  const [hasBeenSubmitted, setFormSubmitted] = useState(false);
  const site = useContext(SiteContext);
  const [store] = useContext(StoreContext);
  const mandateId = useMemo(() => FormService.createMandateId(), []);
  const router = useRouter();
  const { experiments } = useOptimizelyData();
  const fields = mapVariations(originalFields, experiments);
  const initialState = useMemo(
    () => FormService.getInitialState(fields),
    [fields],
  );
  const [state, dispatch] = useReducer(FormService.reducer, initialState);
  const [isPristine, setPristine] = useState(true);
  const [formInitialValues, setFormInitialValues] = React.useState();
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    const fieldsData = id
      ? FormService.getDataFromLocalStorage(id)
      : initialValues || {};
    const formHasSavedData =
      Object.keys(fieldsData).filter((key) => !!fieldsData[key]).length > 0;

    // if there is saved data for the form,
    // we want to show the errors right away and do not wait for form submit
    if (formHasSavedData) {
      setFormSubmitted(true);
    }

    setFormInitialValues(fieldsData);
  }, [id, initialValues]);

  const formName = useMemo(() => uniqueId('form-'), []);

  const sharedProps = useMemo(
    () => ({
      optionalLabel,
      requiredMessage,
      dispatch,
      isPristine,
      hasBeenSubmitted,
    }),
    [isPristine, optionalLabel, requiredMessage, hasBeenSubmitted],
  );

  const fieldComponents = useMemo(
    () =>
      flow(
        FormService.mapFieldsToComponents,
        FormService.autoFullWidthCalculation,
      )({
        components,
        configs,
        fields,
        sharedProps,
        formInitialValues,
        formId: id,
      }),
    [fields, sharedProps, formInitialValues, id],
  );

  if (isEmpty(fields)) {
    return null;
  }

  const handleSubmit = (event) => {
    setFormSubmitted(true);
    const isFormValid = FormService.isFormValid(state);

    if (!isFormValid) {
      event.preventDefault();
      setPristine(false);
      FormService.focusOnFirstError(formRef.current);
      return;
    }

    if (lightVersion && onSubmit) {
      onSubmit(event, setIsLoading);
      return;
    }

    const trackingDepartmentId = departmentId || 'ANY';
    const formTrackingId =
      submitButtonTrackingId || `form_submission@${trackingDepartmentId}`;
    const optlyEventName = `form-submission-${trackingDepartmentId}`;
    OptimizelyFullStack.trackEvent(optlyEventName);

    if (!isEmpty(submitButtonOptimizelyFullStackClickEvents)) {
      OptimizelyFullStack.trackEvents(
        submitButtonOptimizelyFullStackClickEvents,
      );
    }

    const analyticsEvent = {
      event: 'interaction',
      target: 'Mkt_Web',
      contentType,
      contentEntryName: name,
      contentEntryId: id,
      destinationCategory: Analytics.destinationCategory.FORM_SUBMISSION,
      destinationUrl: undefined,
      action: formTrackingId,
    };

    Analytics.sendEvent(analyticsEvent);

    // track lead generation in Google Analytics 4
    elb('generate lead', {
      option: formTrackingId,
    });

    sendNinetailedEvent(formTrackingId);

    if (id) {
      FormService.saveDataToLocalStorage(new FormData(event.target), id);
    }

    if (onSubmit) {
      onSubmit(event, setIsLoading);
    } else if (
      leadReceiver === LEAD_RECEIVERS_CONTENTFUL_OPTIONS.TILLER ||
      leadReceiver ===
        LEAD_RECEIVERS_CONTENTFUL_OPTIONS.TILLER_CARD_READER_LEADS
    ) {
      event.preventDefault();
      setIsLoading(true);
      const formData = new FormData(event.target);

      const leadContext: LeadContext = {
        leadReceiver: 'tiller',
        tillerHandler:
          leadReceiver ===
          LEAD_RECEIVERS_CONTENTFUL_OPTIONS.TILLER_CARD_READER_LEADS
            ? 'card_reader_leads'
            : 'default',
      };

      createLead(leadContext, formData)
        .then((res) => {
          if (res) {
            return res.json();
          }
          return undefined;
        })
        .then((data) => {
          if (isEmpty(data)) {
            window.location.assign(redirectUrl);
            return;
          }

          // track failed lead generation in Google Analytics 4
          elb('generate-lead-validation failed', {
            option: formTrackingId,
          });
        })
        // eslint-disable-next-line no-console
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });

      // "Marketing Cloud" integrations have server-side validation.
    } else if (
      leadReceiver === LEAD_RECEIVERS_CONTENTFUL_OPTIONS.MARKETING_CLOUD
    ) {
      event.preventDefault();
      setIsLoading(true);

      const leadContext: LeadContext = {
        leadReceiver: 'marketingcloud',
        marketingCloudTable: 'generic',
        locale: site.locale,
      };
      const leadData = new FormData(event.target);

      createLead(leadContext, leadData)
        .then((res) => {
          if (res) {
            return res.json();
          }
          return undefined;
        })
        .then((data) => {
          if (isEmpty(data)) {
            window.location.assign(redirectUrl);
            return;
          }

          setValidationErrors(data);

          Analytics.sendEvent({
            ...analyticsEvent,
            action: `form_submission_validation_error@${trackingDepartmentId}_${site.locale}`,
          });

          // track failed lead generation in Google Analytics 4
          elb('generate-lead-validation failed', {
            option: formTrackingId,
          });
        })
        // eslint-disable-next-line no-console
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const additionalHiddenFields = FormService.getAdditionalHiddenFields(
    router.query,
    hiddenField,
    queryParamsInHiddenFields,
  );
  const { locale = '' } = site;
  const [language, country] = locale.split('-');
  const leadSource = `${departmentId}-${locale}`;

  // we store all query params in-memory during page to page navigation
  // in order to be able to send origin LP utm_ params during form submission
  const sessionQuery = store?.sessionQuery || {};
  const {
    utm_campaign: campaignFromUrl,
    utm_source: sourceFromUrl,
    utm_medium: mediumFromUrl,
  } = sessionQuery;
  const queryString = qs.stringify(sessionQuery);

  return (
    <form
      ref={formRef}
      name={formName}
      noValidate
      onSubmit={handleSubmit}
      data-selector={dataSelector('form', DATA_SELECTOR)}
      autoComplete="on"
      data-elbcontext="component:form"
    >
      <HiddenField {...hiddenField.oid} />
      <HiddenField value={leadSource} {...hiddenField.leadSource} />
      <HiddenField value={mandateId} {...hiddenField.mandateId} />
      <HiddenField value={mediumFromUrl} {...hiddenField.medium} />
      <HiddenField value={sourceFromUrl} {...hiddenField.source} />
      <HiddenField value={campaignFromUrl} {...hiddenField.campaign} />
      <HiddenField value={country} {...hiddenField.country} />
      <HiddenField value={language} {...hiddenField.language} />
      <HiddenField value={queryString} {...hiddenField.queryString} />
      <HiddenField
        value={isServer ? '' : window?.location?.href}
        name="form_url"
      />

      {additionalHiddenFields.map((hidField) => (
        <HiddenField key={hidField.name} {...hidField} />
      ))}

      {!lightVersion && (
        <>
          {redirectUrl ? (
            <HiddenField value={redirectUrl} {...hiddenField.redirectUrl} />
          ) : null}
        </>
      )}
      {children(fieldComponents, validationErrors)}
    </form>
  );
}

/**
 * @component
 */
export default Form;
