import get from 'lodash/fp/get';
import values from 'lodash/fp/values';
import set from 'lodash/fp/set';
import findLastIndex from 'lodash/fp/findLastIndex';
import omitBy from 'lodash/fp/omitBy';
import fromPairs from 'lodash/fp/fromPairs';
import toArray from 'lodash/fp/toArray';
import { FC } from 'react';

import { FieldConfig, FormFieldProps, SharedProps } from './constants';

import {
  saveFormSubmissionToLocalStorage,
  saveUserDataToLocalStorage,
  getJSONFromLocalStorage,
  getStringFromLocalStorage,
} from '~/domains/sales-leads/services/client';
import {
  USER_DATA_KEY,
  USER_DATA_KEY_CONVERTFLOW,
} from '~/domains/sales-leads/services/client/constants';
import { LeadData } from '~/domains/sales-leads/services/interfaces';

export function getInitialState(fields = []) {
  return fields.reduce(
    (allFields, field) => ({ ...allFields, [field.id]: !field.required }),
    {},
  );
}

export function mapFieldsToComponents({
  components,
  configs,
  fields,
  sharedProps,
  formInitialValues = {},
  formId,
}: {
  components: Record<string, JSX.Element | FC>;
  configs: {
    textField: FieldConfig;
    checkboxField: FieldConfig;
    quizField: FieldConfig;
    selectField: FieldConfig;
    hiddenField: FieldConfig;
  };
  fields: FormFieldProps[];
  sharedProps: SharedProps;
  formInitialValues: Record<string, string>;
  formId?: string;
}) {
  return fields.reduce((allFields, field) => {
    const { contentType, type } = field;
    const config = get([contentType, type], configs);
    const currentField = components[contentType];

    if (currentField) {
      const props = {
        ...field,
        ...config,
        ...(sharedProps || {}),
        initialValue: formInitialValues[config?.salesforceId],
        formInitialValues,
        formId,
      };

      allFields.push({ Field: currentField, props });
    }

    return allFields;
  }, []);
}

/**
 * Calculate if a text field or a select field should be
 * full width or not. In two column layout, the last line
 * should always be full width for better visual balance.
 */
export function autoFullWidthCalculation(fields) {
  // Ignore checkbox field. A checkbox should always be full width
  // no matter where they are.
  const lastInputIndex = findLastIndex(
    (field) => get('props.contentType', field) !== 'checkboxField',
  )(fields);

  // return original array when no valid field existed.
  if (lastInputIndex < 0) {
    return fields;
  }

  /**
   * Calculate if the last line has dangling text/seleect field.
   * Method:
   *   For each short field, we add one point to the dangling value.
   *   when we meet a full width field or a checkbox field , the calculation
   *   restart all over again. A even final dangling value means no dangling.
   * For example:
   *   short, short
   *   full
   *   short
   * This will have dangling value 1
   */
  const danglingValue = fields
    .slice(0, lastInputIndex + 1)
    .reduce((accu, field) => {
      const isFullWidth = get('props.fullWidth', field) ? 1 : 0;
      const isCheckbox = get('props.contentType', field) === 'checkboxField';

      if (isFullWidth || isCheckbox) {
        return 0;
      }

      return accu + 1;
    }, 0);

  return danglingValue % 2 === 1
    ? Object.assign([...fields], {
        [lastInputIndex]: set('props.fullWidth', true, fields[lastInputIndex]),
      })
    : fields;
}

export function isFormValid(state = {}) {
  return values(state).reduce(
    (allValid, fieldIsValid) => allValid && fieldIsValid,
    true,
  );
}

export function focusOnFirstError(form) {
  for (let i = 0; i < form.length; i += 1) {
    const field = form[0];
    const validity = get('validity.valid', field);
    const isInvalid = typeof validity !== 'undefined' ? validity : false;
    if (isInvalid) {
      form[i].focus();
      break;
    }
  }
}

export function reducer(state, action) {
  const { type, id } = action;
  switch (type) {
    case 'valid':
      return { ...state, [id]: true };
    case 'invalid':
      return { ...state, [id]: false };
    default:
      throw new Error();
  }
}

export function formatPhoneNumber(value) {
  return value.replaceAll(/[^0-9+]*/g, '');
}

export function matchPattern(pattern) {
  return (value) => (value ? pattern.test(value) : true);
}

export function validateLength(length) {
  return (value) => (value ? value.length === length : true);
}

export function validateMaxLength(maxLength) {
  return (value) => (value ? value.length <= maxLength : true);
}

export function validateMinLength(minLength) {
  return (value) => (value ? value.length >= minLength : false);
}

export function flowValidate(...fns) {
  return (value) => fns.reduce((acc, fn) => acc && fn(value), true);
}

export function getAdditionalHiddenFields(
  query = {},
  config = {},
  queryParamsInHiddenFields = [],
): { name: string; value: string }[] {
  const hiddenFieldsNames = Object.keys(config).map((key) => config[key].name);
  const hiddenFieldsNamesSet = new Set(hiddenFieldsNames);

  const filteredQuery = omitBy(
    (value, key) => hiddenFieldsNamesSet.has(key),
    query,
  );

  const queryParamsInHiddenFieldsSet = new Set(queryParamsInHiddenFields);

  return Object.keys(filteredQuery)
    .filter((key) => queryParamsInHiddenFieldsSet.has(key))
    .map((key) => ({
      name: key,
      value: filteredQuery[key] as string,
    }));
}

export function saveDataToLocalStorage(formData, formId) {
  const data = fromPairs(toArray(formData)) as LeadData;

  saveFormSubmissionToLocalStorage(formId, data);
  saveUserDataToLocalStorage(data);
}

export function getDataFromLocalStorage(formId) {
  const formData = getJSONFromLocalStorage(formId);

  const existingUserData = getJSONFromLocalStorage(USER_DATA_KEY);

  const userDataFromConvertflow = getStringFromLocalStorage(
    USER_DATA_KEY_CONVERTFLOW,
  );

  // If there is more than one email available in local storage, the existing user data will prevail over the email from Convertflow
  const data = {
    email: userDataFromConvertflow,
    ...formData.fields,
    ...existingUserData,
  };

  return data;
}

export function padStart(value) {
  return value.toString().padStart(2, '0');
}

function generateRandomString() {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const length = 6;
  let result = '';
  for (let i = length; i > 0; i -= 1) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
}

export function createMandateId() {
  if (__TEST__) {
    return 'random-string';
  }

  const randomString = generateRandomString();
  const date = new Date();
  const year = date.getFullYear();
  const month = padStart(date.getMonth() + 1);
  const day = padStart(date.getDate());
  const hours = padStart(date.getHours());
  const minutes = padStart(date.getMinutes());
  const seconds = padStart(date.getSeconds());
  // eslint-disable-next-line max-len
  return `SDEPOSNE${year}${month}${day}${hours}${minutes}${seconds}${randomString}`;
}
