import { ON_CHANGE, SET_COUNTRY_CODE, SET_FOCUS } from './constants';

const startsWithPlusSign = /^\+/;

export function calculateHiddenFieldValue(value, countryCode) {
  if (!value || !countryCode) {
    return value;
  }

  if (startsWithPlusSign.test(value)) {
    return value;
  }

  return `${countryCode}${value}`;
}

export function reducer(state, { type, value }) {
  let hiddenFieldValue;

  switch (type) {
    case ON_CHANGE:
      hiddenFieldValue = calculateHiddenFieldValue(value, state.countryCode);

      return { ...state, value, hiddenFieldValue };
    case SET_COUNTRY_CODE:
      hiddenFieldValue = calculateHiddenFieldValue(state.value, value);

      return {
        ...state,
        countryCode: value,
        hiddenFieldValue,
      };
    case SET_FOCUS:
      return { ...state, focused: value };
    default:
      return state;
  }
}

export const initialState = {
  value: '',
  countryCode: '',
  focused: false,
  hiddenFieldValue: '',
};
