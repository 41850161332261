import fetch from 'isomorphic-unfetch';
import fromPairs from 'lodash/fp/fromPairs';
import toArray from 'lodash/fp/toArray';

import { LeadContext, LeadData } from '../interfaces';

import { USER_DATA_KEY } from './constants';

import { encodeToBase64, decodeFromBase64 } from '~/shared/util/encoding';
import { UTM_PARAMS } from '~/shared/constants/query-params';
import {
  getConsentStatus,
  getActiveCookieCategoriesFromWindow,
} from '~/shared/services/onetrust';

function transformLeadData(data: FormData | LeadData): LeadData {
  const activeCookieCategories = getActiveCookieCategoriesFromWindow();
  const consentStatus = getConsentStatus(activeCookieCategories);

  const leadData =
    data instanceof FormData ? fromPairs(toArray(data) as any) : data;

  return Object.keys(leadData).reduce((acc, key) => {
    const shouldRemoveUtmParams =
      !consentStatus.performance && Object.values(UTM_PARAMS).includes(key);

    if (shouldRemoveUtmParams) {
      return acc;
    }

    acc[key] = leadData[key];
    return acc;
  }, {});
}

export const createLead = (
  leadContext: LeadContext,
  leadData: FormData | LeadData,
) => {
  const body = {
    leadData: transformLeadData(leadData),
    leadContext,
  };

  return (
    fetch('/api/lead', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      // eslint-disable-next-line no-console
      .catch(console.error)
  );
};

export function getJSONFromLocalStorage(key: string) {
  const data = window.localStorage.getItem(key);

  if (!data) {
    return {};
  }

  try {
    const decodedData = decodeFromBase64(data);

    return JSON.parse(decodedData);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return {};
  }
}

export function getStringFromLocalStorage(key: string) {
  const data = window.localStorage.getItem(key);

  if (!data) {
    return '';
  }

  try {
    const decodedData = decodeFromBase64(data);

    return decodedData;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return '';
  }
}

export function saveToLocalStorage(key: string, data: LeadData) {
  try {
    const encodedData = encodeToBase64(JSON.stringify(data));

    window.localStorage.setItem(key, encodedData);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

export function saveFormSubmissionToLocalStorage(
  formId: string,
  fields: LeadData,
  formDetails?: LeadData,
): Promise<void> {
  return new Promise((resolve) => {
    const existingFormData = getJSONFromLocalStorage(formId);

    saveToLocalStorage(formId, {
      ...existingFormData,
      ...formDetails,
      fields: {
        ...existingFormData.fields,
        ...fields,
      },
    });

    resolve();
  });
}

export function saveUserDataToLocalStorage(fields: LeadData): Promise<void> {
  return new Promise((resolve) => {
    const existingUserData = getJSONFromLocalStorage(USER_DATA_KEY);

    saveToLocalStorage(USER_DATA_KEY, {
      ...existingUserData,
      ...fields,
    });

    resolve();
  });
}
