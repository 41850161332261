import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const baseStyles = () => css`
  font-style: italic;
  color: var(--cui-fg-subtle);

  &::before {
    content: '(';
  }

  &::after {
    content: ')';
  }
`;

const requiredStyles = ({ required }) =>
  required &&
  css`
    display: none;
  `;

const sizeStyles = ({ size, theme }) => {
  const { two, one } = theme.typography.body;

  if (size === 'two') {
    return css`
      ${two}
    `;
  }

  if (size === 'one') {
    return css`
      ${one}
    `;
  }

  return css`
    ${two}
  `;
};

type OptionalLabelProps = {
  children: string;
  required: boolean;
  size: 'one' | 'two';
};

const StyledOptionalLabel = styled('span')<OptionalLabelProps>(
  baseStyles,
  requiredStyles,
  sizeStyles,
);

/**
 * A short label that marks non-required fields as optional.
 */
const OptionalLabel = ({
  children = 'optional',
  required = false,
  size = 'two',
}: OptionalLabelProps): JSX.Element => (
  <StyledOptionalLabel
    data-selector="optional_label"
    required={required}
    size={size}
  >
    {children}
  </StyledOptionalLabel>
);

/**
 * @component
 */
export default OptionalLabel;
