export function getSelectedFields(quizItems) {
  const newSelectedFields =
    quizItems.length && quizItems.filter(({ checked }) => checked);
  return newSelectedFields && newSelectedFields.length
    ? newSelectedFields
    : null;
}

export function getUpdatedQuizFields(quizItems, clickedIndex, multiple) {
  return multiple
    ? quizItems.map((quizItem, index) => ({
        ...quizItem,
        checked: index === clickedIndex ? !quizItem.checked : quizItem.checked,
      }))
    : quizItems.map((quizItem, index) => ({
        ...quizItem,
        checked: index === clickedIndex,
      }));
}
