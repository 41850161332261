import { Theme } from '@sumup/design-tokens';

export const INPUT_TYPES = {
  EMAIL: 'email',
  NUMBER: 'number',
  PHONE: 'tel',
  MOBILE_PHONE: 'tel mobile',
  TEXT: 'text',
  URL: 'url',
};

export const INPUT_AUTOCOMPLETE = {
  EMAIL: 'email',
  PHONE: 'tel',
  FIRST_NAME: 'given-name',
  LAST_NAME: 'family-name',
  URL: 'url',
  ORGANIZATION: 'organization',
  POSTAL_CODE: 'postal-code',
};

export const INPUT_COMPONENTS = {
  INPUT: 'input',
  TEXTAREA: 'textarea',
};

export const LEAD_RECEIVERS_CONTENTFUL_OPTIONS = {
  TILLER: 'Tiller',
  TILLER_CARD_READER_LEADS: 'Tiller_Card_Reader_Leads',
  MARKETING_CLOUD: 'Marketing Cloud',
} as const;

type ReceiversContentfulOptionsKeys =
  keyof typeof LEAD_RECEIVERS_CONTENTFUL_OPTIONS;
export type LeadReceiversContentfulOptionsType =
  (typeof LEAD_RECEIVERS_CONTENTFUL_OPTIONS)[ReceiversContentfulOptionsKeys];

export type SharedProps = {
  optionalLabel?: string;
  requiredMessage: string;
  dispatch: (_) => void;
  isPristine: boolean;
  hasBeenSubmitted: boolean;
  formInitialValues?: {
    // eslint-disable-next-line camelcase
    Country_phone: 'string';
  };
  formId?: string;
};
export interface FormFieldProps extends SharedProps {
  id: string;
  salesforceId?: string;
  validateFn: (_) => boolean;
  required: boolean;
  isPristine: boolean;
  validationMessage?: string;
  requiredMessageOverride?: string;
  initialValue?: string;
  label: string;
  type: string;
  contentType: string;
  dataSelectorValue?: string;
  showSmallText?: boolean;
  placeholder?: string;
  prefillWithCountryCode?: boolean;
}

export interface TextFieldProps extends FormFieldProps {
  inputType: string;
  maxLength?: number;
  dataSelectorValue: string;
  autoComplete?: string;
  formatFn?: (_) => void;
  autoCapitalize?: string;
  wrap?: string;
  infoMessage?: string;
  component?: any;
  countryCodePrefilled?: boolean;
  name: string;
  serverValidationError?: string;
  theme: Theme;
}

export type FieldConfig = Record<
  string,
  Record<string, string | number | ((_) => boolean) | ((string) => string)>
>;
