import React, { useState, useEffect } from 'react';
import { Checkbox, CheckboxProps } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import isEmpty from 'lodash/fp/isEmpty';

import useValidation from '../../hooks/use-validation';
import { FormFieldProps } from '../../constants';

import RichText from '~/shared/components/RichText';
import * as inline from '~/shared/components/RichText/configs/inline';
import dataSelector from '~/shared/util/data-selector';

// Salesforce requires this value to be set.
const VALUE = '1';

const baseStyles = ({ theme }) => css`
  // Override css in circuit-ui to add margin-top
  // and compensate bottom spacing.
  // In circuit-ui, spacings.mega - spacings.bit = spacings.kilo
  &:last-of-type {
    margin-top: ${theme.spacings.bit};
    margin-bottom: ${theme.spacings.kilo};
  }
`;

const StyledCheckbox = styled(Checkbox)<CheckboxProps>(baseStyles);

/**
 * A checkbox field for boolean data.
 */
function CheckboxField(field: FormFieldProps): JSX.Element {
  const [checked, setChecked] = useState(false);
  const [focused, setFocused] = useState(false);

  const { invalid, validationHint } = useValidation({
    field,
    value: checked,
    focused,
  });

  useEffect(() => {
    if (field.initialValue) {
      setChecked(field.initialValue === VALUE);
    }
  }, [field.initialValue]);

  if (isEmpty(field)) {
    return null;
  }

  const {
    salesforceId = '',
    label = '',
    required = false,
    optionalLabel,
    contentType,
    isPristine,
    type,
    dataSelectorValue = '',
    showSmallText = false,
    hasBeenSubmitted: formHasBeenSubmitted,
    formId,
    ...rest
  } = field;

  const handleChange = () => {
    setChecked(!checked);
  };

  const textSize = showSmallText ? inline.TWO : inline.ONE;
  const id = formId ? `${formId}-${field.id}` : field.id;

  return (
    <StyledCheckbox
      {...rest}
      // @ts-expect-error FIXME: Field labels cannot contain
      // interactive elements since those aren't accessible to
      // assistive technologies.
      label={
        <RichText
          richText={label}
          renderNode={inline.createRenderNode(textSize)}
          renderMark={inline.createRenderMark(textSize)}
        />
      }
      value={VALUE}
      checked={checked}
      id={id}
      name={salesforceId}
      onChange={handleChange}
      required={required}
      validationHint={validationHint}
      invalid={invalid && formHasBeenSubmitted}
      data-selector={dataSelector(dataSelectorValue, 'form')}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      data-elbcontext="component:checkbox_field"
      optionalLabel={required ? null : optionalLabel}
    />
  );
}

/**
 * @component
 */
export default React.memo(CheckboxField);
