import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/fp/isArray';
import isEmpty from 'lodash/fp/isEmpty';
import { Select } from '@sumup/circuit-ui';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import useValidation from '../../hooks/use-validation';

import dataSelector from '~/shared/util/data-selector';

const selectStyles = ({ theme }) => css`
  z-index: ${theme.zIndex.absolute};
  margin-bottom: ${theme.spacings.mega};

  & + div,
  & + style + div {
    margin-top: ${theme.spacings.bit};
  }
`;

const StyledSelect = styled(Select)(selectStyles);

/**
 * A dropdown-style select field.
 */
function SelectField(field) {
  const [value, setValue] = useState('');
  const [focused, setFocused] = useState(false);
  const { invalid, validationHint } = useValidation({ field, value, focused });

  const { theme, hasBeenSubmitted: formHasBeenSubmitted, ...fieldRest } = field;

  React.useEffect(() => {
    if (field.initialValue) {
      setValue(field.initialValue);
    }
  }, [field.initialValue]);

  if (isEmpty(fieldRest)) {
    return null;
  }

  const {
    label = '',
    placeholder = '',
    options = [],
    salesforceId = '',
    dataSelectorValue = '',
    required = false,
    optionalLabel = 'optional',
    contentType,
    isPristine,
    dispatch = () => {},
    formId,
    ...rest
  } = field;

  if (!isArray(options)) {
    return null;
  }

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const id = formId ? `${formId}-${field.id}` : field.id;

  return (
    <>
      <StyledSelect
        {...rest}
        data-elbcontext="component:select_field"
        label={label}
        optionalLabel={required ? null : optionalLabel}
        onChange={handleChange}
        id={id}
        name={salesforceId}
        options={options}
        required={required}
        placeholder={placeholder}
        validationHint={validationHint}
        invalid={invalid && formHasBeenSubmitted}
        data-selector={dataSelector(dataSelectorValue, 'form')}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        dispatch={dispatch}
        value={field.initialValue ? value : undefined}
      />
    </>
  );
}

SelectField.propTypes = {
  /**
   * A concise and descriptive prompt or title.
   * It is displayed above the input field.
   */
  label: PropTypes.string.isRequired,
  /**
   * The placeholder should be an example value for this field.
   * It is displayed inside the input field.
   */
  placeholder: PropTypes.string,
  /**
   * An array of options from which the user can select.
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  /**
   * A unique Salesforce field id.
   */
  salesforceId: PropTypes.string.isRequired,
  /**
   * Is this field required to submit the form?
   */
  required: PropTypes.bool,
  /**
   * This message is shown to the user when they enter a value
   * that doesn't match the expected format.
   * Use {value} to interpolate the current field value.
   */
  validationMessage: PropTypes.string,
  /**
   * Callback to inform the form of the validity of the field.
   */
  dispatch: PropTypes.func,
  /**
   * The field will expand across 2 columns
   * when full width is true and 2 column layout is selected.
   */
  fullWidth: PropTypes.bool,
};

/**
 * @component
 */
export default SelectField;
