import { useEffect, useState, useRef } from 'react';
import template from 'lodash/template';

import { FormFieldProps } from '../constants';

type UseValidationReturnType = {
  valid: boolean;
  invalid: boolean;
  validationHint?: string;
};

export function getValidationHint({
  value = '',
  isInvalid,
  isRequired,
  validationMessage = '',
  requiredMessage = '',
  requiredMessageOverride = '',
}: {
  value: string | boolean;
  isInvalid: boolean;
  isRequired: boolean;
  validationMessage?: string;
  requiredMessage: string;
  requiredMessageOverride?: string;
}): string {
  if (isInvalid) {
    const compiled = template(validationMessage, { interpolate: /{(\S+?)}/g });
    return compiled({ value });
  }

  if (isRequired) {
    if (requiredMessageOverride) {
      return requiredMessageOverride;
    }
    return requiredMessage;
  }

  return '';
}

export default function useValidation({
  field,
  value = '',
  focused = false,
}: {
  field: FormFieldProps;
  value: string | boolean;
  focused: boolean;
}): UseValidationReturnType {
  const {
    id,
    validateFn,
    dispatch,
    required,
    isPristine = true,
    validationMessage,
    requiredMessage,
    requiredMessageOverride,
  } = field;

  const isInitialRender = useRef(true);
  const prevFocused = useRef(focused);
  const [isTouched, setTouched] = useState(false);
  const [isInvalid, setInvalid] = useState(false);
  const [isMissing, setMissing] = useState(required && !value);
  const [isBlank, setBlank] = useState(!required && !value);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    if (!isTouched) {
      setTouched(prevFocused.current && !focused);
    }

    prevFocused.current = focused;

    const newIsInvalid = validateFn && value ? !validateFn(value) : false;
    setInvalid(newIsInvalid);

    const newIsMissing = required && !value;
    setMissing(newIsMissing);

    const newIsBlank = !required && !value;
    setBlank(newIsBlank);

    if (dispatch) {
      const type = newIsMissing || newIsInvalid ? 'invalid' : 'valid';
      dispatch({ type, id });
    }
  }, [value, dispatch, validateFn, id, required, focused, isTouched]);

  const showInvalid = isInvalid && isTouched;
  const showRequired = isMissing && (isTouched || !isPristine);
  const invalid = showRequired || showInvalid;

  const valid = !isInvalid && isTouched && !isBlank && !isMissing;

  const validationHint = getValidationHint({
    value,
    isInvalid: showInvalid,
    isRequired: showRequired,
    validationMessage,
    requiredMessage,
    requiredMessageOverride,
  });

  return { valid, invalid, validationHint };
}
