import React from 'react';
import isEmpty from 'lodash/fp/isEmpty';
import { Input, TextArea } from '@sumup/circuit-ui';
import { Confirm } from '@sumup/icons';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import HiddenField from '../HiddenField';
import { INPUT_TYPES, INPUT_COMPONENTS, TextFieldProps } from '../../constants';
import useValidation from '../../hooks/use-validation';

import CountryCodeDropdown from './components/CountryCodeDropdown';
import { ON_CHANGE, SET_FOCUS, SET_COUNTRY_CODE } from './constants';
import countryCodes from './components/CountryCodeDropdown/country-codes';
import * as TextFieldService from './TextFieldService';

import dataSelector from '~/shared/util/data-selector';

const containerStyles = ({ hasCountryCodePrefix }) =>
  hasCountryCodePrefix &&
  css`
    input {
      padding-left: 140px;
    }
  `;

// Safari adds a mysterious margin top and bottom of 2px
// that leads to the country code occupying more space than necessary
const safariMarginRemoveStyles = () => css`
  input {
    margin: 0;
  }
`;

const Container = styled('div')(containerStyles, safariMarginRemoveStyles);

const components = {
  [INPUT_COMPONENTS.INPUT]: Input,
  [INPUT_COMPONENTS.TEXTAREA]: TextArea,
};

const ConfirmIcon = styled(Confirm)`
  color: var(--cui-fg-success) !important;
`;

/**
 * An input field for text.
 */
function TextField(field: TextFieldProps): JSX.Element {
  const [state, innerDispatch] = React.useReducer(
    TextFieldService.reducer,
    TextFieldService.initialState,
  );
  const { valid, invalid, validationHint } = useValidation({
    field,
    value: state.value,
    focused: state.focused,
  });

  const { theme, ...fieldRest } = field;

  React.useEffect(() => {
    let textFieldValue = field.initialValue;

    if (!textFieldValue) {
      return;
    }

    if (field.type === 'Phone number') {
      const initialCountryCode = field.formInitialValues?.Country_phone;

      const foundCountry = Object.values(countryCodes).find(
        (country) => country.countryCode === initialCountryCode,
      );

      if (foundCountry) {
        innerDispatch({ type: SET_COUNTRY_CODE, value: foundCountry.label });
        textFieldValue = textFieldValue.replace(foundCountry.label, '');
      }
    }

    innerDispatch({ type: ON_CHANGE, value: textFieldValue });
  }, [field.formInitialValues?.Country_phone, field.initialValue, field.type]);

  if (isEmpty(fieldRest)) {
    return null;
  }

  const {
    salesforceId = '',
    label = '',
    placeholder = '',
    inputType = INPUT_TYPES.TEXT,
    required = false,
    optionalLabel = 'optional',
    component = INPUT_COMPONENTS.INPUT,
    formatFn,
    validateFn,
    validationMessage,
    requiredMessage,
    contentType,
    isPristine,
    dataSelectorValue = '',
    countryCodePrefilled,
    type,
    name,
    serverValidationError,
    hasBeenSubmitted: formHasBeenSubmitted,
    formId,
    ...rest
  } = field;
  const Component = components[component];

  if (!Component) {
    return null;
  }

  const handleChange = (event) => {
    const newValue = formatFn
      ? formatFn(event.target.value)
      : event.target.value;
    innerDispatch({ type: ON_CHANGE, value: newValue });
  };

  const handleFocus = () => innerDispatch({ type: SET_FOCUS, value: true });
  const handleBlur = () => innerDispatch({ type: SET_FOCUS, value: false });

  const hasCountryCodePrefix = countryCodePrefilled && type === 'Phone number';

  const countryCodePrefix = hasCountryCodePrefix
    ? // eslint-disable-next-line react/display-name
      () => (
        <CountryCodeDropdown
          invalid={invalid}
          focused={state.focused}
          dispatch={innerDispatch as () => void}
          label={label}
          value={state.countryCode}
        />
      )
    : null;

  const renderSuffix =
    valid && formHasBeenSubmitted
      ? (suffixProps) => <ConfirmIcon size="16" {...suffixProps} />
      : null;
  const id = formId ? `${formId}-${field.id}` : field.id;

  return (
    <>
      <Container
        hasCountryCodePrefix={hasCountryCodePrefix}
        data-elbcontext="component:text_field"
        style={{ marginBottom: theme.spacings.mega }}
      >
        <Component
          {...rest}
          label={label}
          optionalLabel={required ? null : optionalLabel}
          value={state.value}
          onChange={handleChange}
          id={id}
          type={inputType}
          required={required}
          validationHint={
            formHasBeenSubmitted
              ? validationHint || serverValidationError
              : field.infoMessage
          }
          placeholder={placeholder}
          invalid={(invalid || !!serverValidationError) && formHasBeenSubmitted}
          renderSuffix={renderSuffix}
          data-selector={dataSelector(dataSelectorValue, 'form')}
          onFocus={handleFocus}
          onBlur={handleBlur}
          renderPrefix={countryCodePrefix}
        />
      </Container>
      <HiddenField name={salesforceId} value={state.hiddenFieldValue} />
    </>
  );
}

/**
 * @component
 */
export default TextField;
