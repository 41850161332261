import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Selector } from '@sumup/circuit-ui/legacy';
import { Alert } from '@sumup/icons';
import isArray from 'lodash/fp/isArray';
import isEmpty from 'lodash/fp/isEmpty';

import OptionalLabel from '../OptionalLabel';
import useValidation from '../../hooks/use-validation';

import { getSelectedFields, getUpdatedQuizFields } from './QuizFieldService';

import dataSelector from '~/shared/util/data-selector';
import Icon from '~/shared/components/icons/Icon';

const quizItemStyles = ({ theme, isFirst, isLast, invalid }) => css`
  ::before {
    border: ${theme.borderWidth.kilo} solid var(--cui-border-divider);
    border-bottom: 0;
    border-radius: 0;
    ${isFirst &&
    `
      border-radius: ${theme.borderRadius.byte} ${theme.borderRadius.byte} 0 0;
  `}

    ${isLast &&
    `
      border-radius: 0 0 ${theme.borderRadius.byte} ${theme.borderRadius.byte};
      border-bottom: ${theme.borderWidth.kilo} solid var(--cui-border-divider);
  `}

      ${invalid &&
    `
      border: ${theme.borderWidth.kilo} solid va(--cui-border-danger);
      `}
  }

  label {
    width: 100%;
  }

  display: flex;
  align-items: center;
  gap: ${theme.spacings.mega};
  padding: ${theme.spacings.mega} ${theme.spacings.giga};
  color: var(--cui-fg-normal);
  font-size: ${theme.typography.body.one.fontSize};
  font-weight: ${theme.fontWeight.bold};
  margin-bottom: 0;
`;
const QuizItem = styled(Selector)(quizItemStyles);

const validationErrorStyles = ({ theme }) => css`
  display: flex;
  align-items: center;
  color: var(--cui-fg-danger);
  font-size: ${theme.typography.body.two.fontSize};
  gap: ${theme.spacings.bit};
  margin-top: ${theme.spacings.bit};
`;

const ValidationError = styled('span')(validationErrorStyles);

const styledLabelStyles = ({ theme }) => css`
  & + div,
  & + style + div {
    margin-top: ${theme.spacings.bit};
  }
  margin-bottom: ${theme.spacings.bit};
  font-size: ${theme.typography.body.two.fontSize};
`;
const StyledLabel = styled('label')(styledLabelStyles);

const styledQuizFieldWrapper = ({ theme }) => css`
  margin-bottom: ${theme.spacings.mega};
`;

const QuizFieldWrapper = styled('div')(styledQuizFieldWrapper);

const iconStyles = ({ theme }) => css`
  width: ${theme.iconSizes.mega};
  height: ${theme.iconSizes.mega};
`;

const StyledIcon = styled(Icon)(iconStyles);
/**
 * A quiz field.
 */
function QuizField(field) {
  const {
    options = [],
    multiple,
    dispatch,
    label,
    required,
    optionalLabel,
    requiredMessage,
    onChange,
    salesforceId,
    initialValue,
    hasBeenSubmitted: formHasBeenSubmitted,
  } = field;
  const [quizItems, setQuizItems] = useState(() =>
    isArray(options)
      ? options.map((item) => ({ ...item, checked: item.preselected }))
      : [],
  );
  const selectedFields = React.useMemo(
    () => getSelectedFields(quizItems),
    [quizItems],
  );
  const { invalid, validationHint } = useValidation({
    field,
    value: selectedFields,
  });

  useEffect(() => {
    if (initialValue) {
      setQuizItems(
        quizItems.map((item) => ({
          ...item,
          checked: item.value === initialValue,
        })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  if (!isArray(options) || isEmpty(options)) {
    return null;
  }

  const quizItemClicked = (clickedIndex, event) => {
    const updatedQuizItems = getUpdatedQuizFields(
      quizItems,
      clickedIndex,
      multiple,
    );
    setQuizItems(updatedQuizItems);
    onChange(event);
  };

  return (
    <QuizFieldWrapper data-elbcontext="component:quiz_field">
      <StyledLabel>
        {label}{' '}
        <OptionalLabel required={required}>{optionalLabel}</OptionalLabel>
      </StyledLabel>
      {quizItems.map(
        ({ value, icon = {}, label: itemLabel, checked = false }, index) => {
          const iconUrl = icon.image?.file?.url;

          return (
            <QuizItem
              icon={
                iconUrl
                  ? (iconProps) => (
                      <StyledIcon {...iconProps} src={iconUrl} alt="" />
                    )
                  : null
              }
              label={itemLabel}
              isFirst={index === 0}
              isLast={index === quizItems.length - 1}
              key={value}
              value={value}
              name={salesforceId || 'quizField'}
              onChange={(event) => {
                quizItemClicked(index, event);
              }}
              dispatch={dispatch}
              multiple={multiple}
              checked={checked}
              requiredMessage={requiredMessage}
              validationHint={validationHint}
              invalid={invalid && formHasBeenSubmitted}
              data-selector={dataSelector('quizItem', 'quizField')}
            />
          );
        },
      )}

      {invalid && validationHint && formHasBeenSubmitted && (
        <ValidationError>
          <Alert size="16" />
          {validationHint}
        </ValidationError>
      )}
    </QuizFieldWrapper>
  );
}

QuizField.propTypes = {
  /**
   * A concise and descriptive prompt or title.
   * It is displayed above the input field.
   */
  label: PropTypes.string,
  /**
   * An array of options from which the user can select.
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      icon: PropTypes.object,
      preselected: PropTypes.bool,
    }),
  ).isRequired,
  /**
   * Is this field required to submit the form?
   */
  required: PropTypes.bool,
  /**
   * Callback to inform the selected value as changed
   */
  dispatch: PropTypes.func,
  /**
   * Boolean to define if multiple selection is allowed
   */
  multiple: PropTypes.bool,
  /**
   * Callback to inform the selected value as changed
   */
  onChange: PropTypes.func,
};

/**
 * @component
 */
export default QuizField;
